/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const PrivacyPolicy = ({ data, ...props }) => (
  <Layout bodyClass="page-privacy-policy" {...props}>
    <SEO title="Privacy Policy" />
    <div className="container pt-2 pt-md-6 pb-2 pb-md-4">
      <div className="row">
        <div className="col-12">
          <h2 className="text-center font-weight-bolder">Privacy Policy</h2>
          <p>Effective date: 2/12/2020</p>
          <p>
            At Entangled, we take your privacy seriously. Please read the following to learn how we treat your personal information. <strong>By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.</strong>
          </p>
          <p>
            Remember that your use of Entangled&apos;s Services is at all times subject to our <Link to="/terms-of-use">Terms of Use</Link>, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of Use.
          </p>
          <h6 className="font-weight-bold text-underline">What this Privacy Policy Covers</h6>
          <p>This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. “Personal Data” means any information that identifies or relates to a particular individual and also includes information referred to as “personally identifiable information” or “personal information” under applicable data privacy laws, rules, or regulations. This Privacy Policy does not cover the practices of companies we don’t own or control or people we don’t manage.</p>
          <h6 className="font-weight-bold text-underline">Sources of Personal Data</h6>
          <p>We collect Personal Data about you from:</p>
          <ul>
            <li>You:
              <ul>
                <li>when you provide such information directly to us, and</li>
                <li>when Personal Data about you is automatically collected in connection with your use of our Services.</li>
              </ul>
            </li>
            <li>Our subsidiaries and affiliates (together, “Affiliates”), when they provide us with Personal Data about you.</li>
            <li>Third parties, when they provide us with Personal Data about you (“Third Parties”). Third Parties that share your Personal Data with us include:
              <ul>
                <li><span className="text-underline">Service providers</span>. For example, we may use analytics service providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support.</li>
              </ul>
            </li>
          </ul>
          <p>The following section provides additional information about how we collect your Personal Data.</p>
          <h6 className="font-italic text-underline">Information Collected Automatically</h6>
          <p>The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs, and JavaScript (collectively, “Cookies”) to enable our servers to recognize your web browser and tell us how and when you visit and use our Services, to analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data– usually text files – placed on your computer, tablet, phone, or similar device when you use that device to visit our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Services do not support “Do Not Track” requests sent from a browser at this time.</p>
          <p>We use the following types of Cookies:
          </p>
          <ul>
            <li>
              <span className="text-underline">Performance/Analytical Cookies</span>. Performance/Analytical Cookies allow us to understand how visitors use our Services such as by collecting information about the number of visitors to the Services, what pages visitors view on our Services and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services’ content for those who engage with our advertising.
            </li>
          </ul>
          <p>You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your computer. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some of the Services and functionalities may not work.</p>
          <p>To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your browser’s menu. To find out more information about Cookies, including information about how to manage and delete Cookies, please visit http://www.allaboutcookies.org/.</p>
          <h6 className="font-weight-bold text-underline">How We Use Your Personal Data</h6>
          <p>We process Personal Data to operate, improve, understand and personalize our Services. We use Personal Data for the following purposes:</p>
          <ul>
            <li>To meet or fulfill the reason you provided the information to us.</li>
            <li>To communicate with you about the Services, including Service announcements, updates or offers.</li>
            <li>To provide support and assistance for the Services.</li>
            <li>To respond to user inquiries and fulfill user requests.</li>
            <li>To protect against or deter fraudulent, illegal or harmful actions and maintain the safety, security and integrity of our Services.</li>
            <li>To comply with our legal or contractual obligations, resolve disputes, and enforce our Terms of Use.</li>
            <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
          </ul>
          <p>We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
          <p>As noted in the list above, we may communicate with you if you’ve provided us the means to do so. For example, if you’ve given us your email address, we may email you about your use of the Services. Also, we may receive a confirmation when you open an email from us, which helps us improve our Services. If you do not want to receive communications from us, please indicate your preference by emailing us at <a href="mailto:info@theriseprize.com" target="_blank" rel="noopener noreferrer">info@theriseprize.com</a>.</p>
          <h6 className="font-weight-bold text-underline">How We Share Your Personal Data</h6>
          <h6 className="font-italic text-underline">Business Transfers</h6>
          <p>All Personal Data may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.</p>
          <h6 className="font-italic text-underline">Government Requests; Legal Process</h6>
          <p>Sometimes, we may be required to hand over information to the government or as part of a legal process (like a lawsuit or arbitration). If we are permitted to do so, we will notify you or the applicable Third-Party User that provided us with such information of any such requirement before we comply. We intend to defend our customers’ rights against unlawful search and seizure. However, we also refuse to support criminal activity.</p>
          <h6 className="font-weight-bold text-underline">Data Security and Retention</h6>
          <p>We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. For example, the Services use industry standard Secure Sockets Layer (SSL) technology to allow for the encryption of Personal Data you provide to us. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the Internet or storing data is completely secure. We cannot guarantee the complete security of any data you share with us, and except as expressly required by law, we are not responsible for the theft, destruction, loss or inadvertent disclosure of your information or content.</p>
          <p>We retain Personal Data about you for as long as necessary to provide you with our Services. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.</p>
          <h6 className="font-weight-bold text-underline">Personal Data of Children</h6>
          <p>As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data from children under 13; if you are a child under 13, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us Personal Data, please contact us at <a href="mailto:info@theriseprize.com" target="_blank" rel="noopener noreferrer">info@theriseprize.com</a>.</p>
          <h6 className="font-weight-bold text-underline">Other State Law Privacy Rights</h6>
          <h6 className="font-italic text-underline">California Resident Rights</h6>
          <p>Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at <a href="mailto:info@theriseprize.com" target="_blank" rel="noopener noreferrer">info@theriseprize.com</a>.</p>
          <h6 className="font-italic text-underline">Nevada Resident Rights</h6>
          <p>If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at <a href="mailto:info@theriseprize.com" target="_blank" rel="noopener noreferrer">info@theriseprize.com</a> with the subject line “Nevada Do Not Sell Request” and providing us with your name. Please note that we do not currently sell your Personal Data as sales are defined in Nevada Revised Statutes Chapter 603A.</p>
          <h6 className="font-weight-bold text-underline">Changes to this Privacy Policy</h6>
          <p>We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time as well, but we will alert you to changes by placing a notice on the <Link to="/">https://theriseprize.com/</Link> website, by sending you an email, and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.</p>
          <h6 className="font-weight-bold text-underline">Contact Information</h6>
          <p>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data, your choices and rights regarding such use, please do not hesitate to contact us at:</p>
          <ul>
            <li><Link to="/">https://theriseprize.com/</Link></li>
            <li><a href="mailto:info@theriseprize.com" target="_blank" rel="noopener noreferrer">info@theriseprize.com</a></li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
);

export default PrivacyPolicy;
